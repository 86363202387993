<template>
  <account-setting-payee />
</template>

<script>
import AccountSettingPayee from '../account-setting/AccountSettingPayee.vue'

export default {
  components: {
    AccountSettingPayee
  },
};
</script>